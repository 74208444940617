<template>
  <div class="page-wrapper">
    <BaseTableLoader :loading="isDataLoading">
      <div>
        <BaseTableHeader
          :title="$t('supplying')"
          :create-button="$t('create_purchase')"
          @new-item="crudMixin_openForm('inventoryItemPurchase', newInventoryItemPurchaseTemplate)"
        >
          <template v-slot:append>
            <InventoryItemPurchaseFilter
              :filters="inventoryItemPurchaseFilterParams"
              :default-filters="defaultInventoryItemPurchaseFilterParams"
              @change="updateFilter"
              @reset="resetFilter"
            />
          </template>
        </BaseTableHeader>

        <InventoryItemPurchaseTable
          :rows="inventoryItemPurchaseArray"
          :pagination="inventoryItemPurchasePagination"
          linkify-supplier
          show-material-title
          show-order-numbers
          show-price-history-button
          @change-page="getInventoryItemPurchases"
          @update="updateInventoryItemPurchase"
          @delete="deleteInventoryItemPurchase"
          @edit="crudMixin_openForm('inventoryItemPurchase', $event)"
          @click:supplier="
            getInventoryItemPurchases(1, {
              ...inventoryItemPurchaseFilterParams,
              supplier_contact_id: $event.id,
              supplier: $event,
            })
          "
        />
      </div>
    </BaseTableLoader>

    <BaseDialog v-model="isInventoryItemPurchaseFormOpen" max-width="1000" persistent scrollable>
      <InventoryItemPurchaseForm
        :dialog="isInventoryItemPurchaseFormOpen"
        :form-item="inventoryItemPurchaseFormItem"
        @cancel="isInventoryItemPurchaseFormOpen = false"
        @create="crudMixin_created('inventoryItemPurchase', $event, true)"
        @update="crudMixin_updated('inventoryItemPurchase', $event)"
      />
    </BaseDialog>
  </div>
</template>

<script>
import crudMixin from '@/mixins/crud-mixin';
import InventoryItemPurchaseForm from '@/components/forms/InventoryItemPurchaseForm';
import BaseTableLoader from '@/components/base/BaseTableLoader';
import BaseTableHeader from '@/components/base/BaseTableHeader';
import InventoryItemPurchaseFilter from '@/components/filters/InventoryItemPurchaseFilter';
import inventoryItemPurchaseService, {
  getDefaultInventoryItemPurchaseFormItem,
} from '@/api/inventory-item-purchase-service';
import InventoryItemPurchaseTable from '@/components/tables/InventoryItemPurchaseTable';
import BaseDialog from '@/components/base/BaseDialog';
import clone from 'just-clone';

export default {
  name: 'InventoryItemPurchases',

  components: {
    BaseDialog,
    InventoryItemPurchaseTable,
    InventoryItemPurchaseFilter,
    BaseTableHeader,
    BaseTableLoader,
    InventoryItemPurchaseForm,
  },

  mixins: [crudMixin],

  data() {
    return {
      inventoryItemPurchaseArray: [],
      inventoryItemPurchasePagination: {
        page: 1,
      },
      inventoryItemPurchaseFormItem: {},
      inventoryItemPurchaseFilterParams: {},
      isInventoryItemPurchaseFormOpen: false,
      isDataLoading: true,
      newInventoryItemPurchaseTemplate: getDefaultInventoryItemPurchaseFormItem(),
      defaultInventoryItemPurchaseFilterParams: {
        order_part_status: ['not_started', 'prepared', 'in_progress'],
        status: ['not_ordered', 'inquired', 'ordered'],
      },
    };
  },

  beforeRouteUpdate(to, from, next) {
    this.inventoryItemPurchaseFilterParams = clone(to.query);
    this.getInventoryItemPurchases(1, this.inventoryItemPurchaseFilterParams);
    next();
  },

  created() {
    if (this.$route.query.search) {
      this.inventoryItemPurchaseFilterParams = clone(this.$route.query);
      this.inventoryItemPurchaseFilterParams.order_part_status = [];
      this.inventoryItemPurchaseFilterParams.status = ['arrived'];
    } else {
      this.inventoryItemPurchaseFilterParams = JSON.parse(
        JSON.stringify(this.defaultInventoryItemPurchaseFilterParams),
      );
    }

    if (this.$route.query.title) {
      this.inventoryItemPurchaseFilterParams.title = this.$route.query.title;
    }
    this.getInventoryItemPurchases(1, this.inventoryItemPurchaseFilterParams);
  },

  methods: {
    getInventoryItemPurchases(page, params) {
      this.inventoryItemPurchaseFilterParams = JSON.parse(JSON.stringify(params));
      if (!params.supplier_contact_id) {
        this.$set(this.inventoryItemPurchaseFilterParams, 'supplier', null);
      }
      this.crudMixin_getPage(
        inventoryItemPurchaseService.getPage,
        'inventoryItemPurchase',
        page,
        this.inventoryItemPurchaseFilterParams,
      );
    },

    updateFilter(newFilters) {
      const newFiltersCopy = newFilters;
      Object.keys(newFiltersCopy).forEach(key => {
        if (!newFiltersCopy[key]) {
          delete newFiltersCopy[key];
        }
      });
      this.$router.push({ name: this.$route.name, query: newFiltersCopy });
    },

    resetFilter() {
      this.$router.push({
        name: this.$route.name,
        query: this.defaultInventoryItemPurchaseFilterParams,
      });
      this.inventoryItemPurchaseFilterParams = {};
      this.inventoryItemPurchaseFilterParams = JSON.parse(
        JSON.stringify(this.defaultInventoryItemPurchaseFilterParams),
      );
      this.getInventoryItemPurchases(1, this.inventoryItemPurchaseFilterParams);
    },

    async updateInventoryItemPurchase(item) {
      const { data } = await this.crudMixin_update(
        inventoryItemPurchaseService.update,
        'inventoryItemPurchase',
        item,
      );
      this.crudMixin_updated('inventoryItemPurchase', data);
    },

    deleteInventoryItemPurchase(item) {
      this.crudMixin_delete(inventoryItemPurchaseService.delete, 'inventoryItemPurchase', item);
    },
  },
};
</script>

<style scoped></style>
