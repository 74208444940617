<template>
  <BaseFilterDrawer :selected-filter-count="selectedFilterCount" @reset="filterMixin_resetFilter">
    <v-text-field
      :value="filters.search"
      :label="$t('search')"
      prepend-icon="mdi-magnify"
      clearable
      @input="filterMixin_handleDebounce('search', $event)"
    />

    <BaseAutocomplete
      v-model="filters.supplier_contact_id"
      :initial-item="filters.supplier"
      :search-function="searchSuppliers"
      :label="$t('supplier')"
      clearable
      @input="filterMixin_updateFilter('supplier_contact_id', $event)"
    />

    <BaseDatepickerInput
      :value="filters.planned_arrival_at_from"
      :label="$t('planned_arrival_at_from')"
      clearable
      @input="filterMixin_updateFilter('planned_arrival_at_from', $event)"
    />

    <BaseDatepickerInput
      :value="filters.planned_arrival_at_to"
      :label="$t('planned_arrival_at_to')"
      clearable
      @input="filterMixin_updateFilter('planned_arrival_at_to', $event)"
    />

    <div class="label mt-6">
      {{ $t('order_part_statuses_label') }}
    </div>
    <v-checkbox
      v-for="(status, index) in orderPartStatuses"
      :input-value="filters.order_part_status"
      :key="status.value"
      :label="status.text"
      :value="status.value"
      :class="{ 'mt-0': index === 0 }"
      hide-details
      @change="filterMixin_updateFilter('order_part_status', $event)"
    />

    <div class="label mt-6">
      {{ $t('purchase_statuses') }}
    </div>
    <v-checkbox
      v-for="(status, index) in purchaseStatuses"
      :input-value="filters.status"
      :key="status.value"
      :label="status.text"
      :value="status.value"
      :class="{ 'mt-0': index === 0 }"
      hide-details
      @change="filterMixin_updateFilter('status', $event)"
    />
  </BaseFilterDrawer>
</template>

<script>
import BaseAutocomplete from '../base/BaseAutocomplete';
import contactService from '../../api/contact-service';
import { ORDER_PART_STATUSES } from '@/api/order-part-service';
import { INVENTORY_ITEM_PURCHASE_STATUSES } from '@/api/inventory-item-purchase-service';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import filterMixin from '@/mixins/filter-mixin';
import BaseFilterDrawer from '@/components/filters/BaseFilterDrawer';

export default {
  name: 'InventoryItemPurchaseFilter',

  components: { BaseFilterDrawer, BaseDatepickerInput, BaseAutocomplete },

  mixins: [filterMixin],

  computed: {
    purchaseStatuses() {
      return INVENTORY_ITEM_PURCHASE_STATUSES;
    },

    orderPartStatuses() {
      return ORDER_PART_STATUSES;
    },
  },

  methods: {
    searchSuppliers: contactService.searchSuppliers,
  },
};
</script>

<style scoped></style>
